import { ResultStatuses } from '@netpurpose/api'
import { ResultStatusesEnum } from '@netpurpose/api/src/generated/facts'
import { formatDateAlternateWithTime } from '@netpurpose/utils'
import { ThemeColor } from '../../themes'
import { Icon, IconName } from '../Icon'
import { IconButton } from '../IconButton'
import Spinner from '../Spinner'
import {
  ActionContainer,
  Container,
  ExportName,
  IconContainer,
  StatusTitleDateContainer,
  TextContainer,
} from './ExportCard.style'

type StatusIcon = {
  title: string
  icon: IconName
  color: ThemeColor
  Action?: () => JSX.Element
}

const getStatusData = ({
  status,
  onDownload,
}: {
  status: ResultStatuses
  onDownload: () => void
}): StatusIcon => {
  switch (status) {
    case ResultStatusesEnum.COMPLETE:
      return {
        title: 'Export ready',
        icon: 'Success',
        color: 'semanticPositive',
        Action: () => (
          <IconButton
            icon="Download"
            iconAlt="Download"
            alignCenter
            color="typePrimary"
            onClick={onDownload}
          />
        ),
      }
    case ResultStatusesEnum.PENDING:
      return {
        title: 'Preparing export',
        icon: 'Downloading',
        color: 'typePrimary',
        Action: () => <Spinner alternate />,
      }
    default:
      return {
        title: 'Export failed.',
        icon: 'ExclamationInCircle',
        color: 'semanticNegative',
      }
  }
}

export type Props = {
  fileDisplayName: string
  status: ResultStatuses
  completedAt?: string
  withBorder?: boolean
  onDownload?: () => void
}

// This component is similar to ExportItem in client-app -> ExportPopUp.tsx, but
// has several small styling and functional differences.
export const ExportCard = ({
  fileDisplayName,
  status,
  completedAt,
  withBorder = true,
  onDownload = () => null,
}: Props) => {
  const statusData = getStatusData({
    status,
    onDownload,
  })

  const formattedDate = completedAt ? formatDateAlternateWithTime(new Date(completedAt)) : ''

  return (
    <Container $withBorder={withBorder} data-testid="export-history-card">
      <IconContainer>
        <Icon icon={statusData.icon} color={statusData.color} alt="" />
      </IconContainer>
      <TextContainer>
        <ExportName>{fileDisplayName}</ExportName>
        <StatusTitleDateContainer>
          {statusData.title}
          {formattedDate && (
            <>
              <span>•</span>
              {formattedDate}
            </>
          )}
        </StatusTitleDateContainer>
      </TextContainer>
      {statusData.Action && (
        <ActionContainer>
          <statusData.Action />
        </ActionContainer>
      )}
    </Container>
  )
}
